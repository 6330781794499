import React from 'react'
import { Whychoose } from '../../assets'

function WhyWork() {
    return (
        <div className='WhyWork wow fadeInUp' data-wow-delay="0.5s">

            <div className='row'>
                <div className='col-md-6 DetailsContainer'>

                    <div>
                        <h1>Why Choose to Work at Vikgol?</h1>

                        <p>At Vikgol, we cultivate a workplace where talent thrives. Experience a fulfilling career journey marked by a harmonious blend of professional growth and personal well-being. Be part of a dynamic team united by innovation and a shared commitment to excellence. We offer ample opportunities for skill development and advancement, empowering you to reach your full potential.</p>

                    </div>
                </div>
                <div className='col-md-6 ChooseImg'>
                    <img src={Whychoose} alt="" className='w-100' />

                </div>
            </div>

        </div>
    )
}

export default WhyWork

import React from 'react'
import { Bicon1, Bicon2, Bicon3, Bicon4, Bicon5, Hands, Nextb, next } from '../../assets'
import Slider from "react-slick";

function BannerSection() {

  const settings = {
    vertical: true,
    verticalSwiping: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000, // Change speed as needed
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false, // Hide arrows for a cleaner look
    dots: false,   // Hide dots
  };

  const textItems = [
    // "Generative AI",
    // "Artificial Intelligence",
    // "AR and Mixed Reality Lab",
    // "Web App Development",
    // "Mobile App Development",
    // "UI/UX Design",
    // "Agile & DevOps",
    // "Digital Marketing",
    // "Internet of Things",
    "E-commerce|",
    "Healthcare|",
    "EdTech|",
    "Shipping|",
    "MarTech|",
    "PropTech|",
    "FinTech|"
  ];

  return (
    <div className="bannerLinear">
      <div className="bannerSection pb-5">
        <div className="w-100 d-flex justify-content-end Btn-Outside">
          <div className="w-10 bg-white">
            <a
              href="https://calendly.com/harsh-46/30min?month=2024-08"
              target="_blank"
            >
              <button
                type="submit"
                className="btn mt-5 btn-primary btn-visonimpout Out-side-btn  btn-lg wow fadeInUp"
                data-wow-delay="1s"
              >
                <span>Contact us</span>{" "}
                <img className="mt-1 imgNext" src={Nextb} alt="" />{" "}
              </button>
            </a>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 ">
            <h2
              className="Banner_heading mt-5 wow fadeInUp"
              data-wow-delay="1s"
            >
              Discover the Innovators Powering Global Change
            </h2>
            <p className="desKick wow fadeInUp pe-5" data-wow-delay="1.4s">
            We fuel the growth of industry-leading disruptors with our top-tier development and staffing solutions. Our skilled team helps businesses thrive and lead in today’s rapidly evolving market.
            </p>
            <Slider {...settings}>
              {textItems.map((item, index) => (
                <div key={index}>
                  <h3 className="Text-effect">
                    <span className="iconLeft"></span> {item}
                  </h3>
                </div>
              ))}
            </Slider>

            <a
              href="https://calendly.com/harsh-46/30min?month=2024-08"
              target="_blank"
            >
              <button
                type="submit"
                className="btn mt-5 btn-primary btn-visonimpout  btn-lg"
                style={{
                  position: "relative",
                  overflow: "hidden",
                  transition: "box-shadow 0.3s ease-in-out",
                }}
                onMouseEnter={(e) =>
                  (e.target.style.boxShadow =
                    "0px 6px 40px -3px rgba(139,58,233,1)")
                }
                onMouseLeave={(e) => (e.target.style.boxShadow = "none")}
              >
                <span>Contact us</span>{" "}
                <img className="mt-1 imgNext" src={Nextb} alt="" />
              </button>
            </a>
          </div>
          <div className="col-md-6 pt-3 ">
            <div className="w-100 Images_container">
              <img
                src={Bicon1}
                alt=""
                className="img1 wow fadeInUp"
                data-wow-delay="1.5s"
              />
              <img
                src={Bicon4}
                alt=""
                className="img2 wow fadeInUp"
                data-wow-delay="1.8s"
              />
              <img
                src={Bicon2}
                alt=""
                className="img3 wow fadeInUp"
                data-wow-delay="2.1s"
              />
              <img
                src={Bicon5}
                alt=""
                className="img4 wow fadeInUp"
                data-wow-delay="2.4s"
              />
              <img
                src={Bicon3}
                alt=""
                className="img5 wow fadeInUp"
                data-wow-delay="2.7s"
              />
            </div>

            <img
              src={Hands}
              alt=""
              className="wow fadeInUp"
              data-wow-delay="1s"
            />
          </div>
        </div>
      </div>

      <div className="col-md-6 bannerMobile px-3 pb-5 pt-2 justify-content-center">
        <h2 className="Banner_headingmobile mt-5">
          Accelerate your business with digital transformation
        </h2>

        <p className="Text-effect mt-4">
          {" "}
          <span className="iconLeft"></span> <span id="spin"></span>
        </p>
        <a
          href="https://calendly.com/harsh-46/30min?month=2024-08"
          target="_blank"
        >
          <button
            type="submit"
            className="btn mt-5 btn-primary m-auto btn-visonimpout  btn-lg"
          >
            <span>Contact us</span>{" "}
            <img className="mt-1 imgNext" src={Nextb} alt="" />
          </button>
        </a>
      </div>
    </div>
  );
}

export default BannerSection